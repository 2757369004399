import React from "react";
import classNames from "classnames";
import { Container, Text, Button, Image } from "@atoms";

const ImageCopy = ({ heading, copy, button, image, flip, crop, color }) => {
  const isDark =
    color === "bg-pink" ||
    color === "bg-purple" ||
    color === "bg-teal-dark" ||
    color === "bg-teal-darker" ||
    color === "bg-black";

  return (
    <Container>
      <div className="flex flex-col items-center gap-10 md:flex-row md:gap-24">
        {image && (
          <div
            className={classNames(
              "relative order-1 w-[60%] md:w-auto md:flex-1",
              {
                "md:order-2": flip,
              }
            )}
          >
            <Image
              image={image}
              aspectRatio={crop ? [1, 1] : null}
              ixParams={!crop ? { fit: "max", crop: false } : undefined}
              className={classNames({
                "rounded-full": crop,
              })}
            />
          </div>
        )}
        <div
          className={classNames(
            "order-2 flex w-full flex-grow flex-col items-center gap-5 text-center md:flex-1 md:items-start md:text-left",
            {
              "md:order-1": flip,
              "text-white": isDark,
            }
          )}
        >
          <Text variant="h2">{heading}</Text>
          <Text variant="sm" className="whitespace-pre-line">
            {copy}
          </Text>
          {!!button?.url && (
            <Button to={button.url} color={isDark ? "blackPink" : "pink"}>
              {button.text}
            </Button>
          )}
        </div>
      </div>
    </Container>
  );
};

ImageCopy.defaultProps = {};

export default ImageCopy;
